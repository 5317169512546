.subInfo{
    color: darkgrey;
    font-size: 12px;
}
.navbarButtons{
    -webkit-tap-highlight-color: transparent;
    width: 80px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    color: grey;
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
}
.navbarButtons.active {
    outline: 0;
    color: #FFCC00;
}
.backButton{
    align-self: center;
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    background-image: url("/src/Images/BackButton.png");
}
.categoryItems{
    width: 30vw;
    height: 36px;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    background-size: cover;
    background-position: center;
    background-color: rgb(0,0,0,0.3);
    text-decoration: none;
    color: white;
    gap: 10px;
}
.categoryItems.active{
    background: #FFFFFF;
    color: #1F2F79;
}
.durationButton{
    width: 120px;
    height: 24px;
    color: darkgray;
    background: none;
    border: none;
}
.durationButton.active{
    border-bottom: 1px solid #FFCC00;
    color: #FFCC00;
}
.inviteUserButton{
    background-color: transparent;
    background-image: url("/src/Images/InviteUser.png");
    background-position: center;
    background-repeat: no-repeat;
    border:1px dashed grey;
}
.createLeagueButton{
    display:flex;
    width:358px;
    height:48px;
    margin-top:8px;
    border-radius:4px;
    border:none;
    background-color: #FFFFFF33;
    color:darkgray;
    font-weight:bold;
    font-size:18px;
    text-decoration:none;
    justify-content:center;
    align-items:center;
}
.createLeagueButton.active{
    margin-top:8px;
    background-color: #FFCC00;
    color:#1F2F79;
}
.joinedLeagueButton{
    display:flex;
    justify-self: start;
    width: 100vw;
    height:44px;
    position: fixed;
    left: 0;
    bottom: 8vh;
    background: rgb(0,0,0,0.3);
    text-align:left;
    text-decoration:none;
    padding-left:16px;
    padding-top:8px
}
.predictNowButton{
    width: 80px;
    height: 16px;
    grid-area: second-column-down;
    margin-top: 32px;
    font-size: 12px;
    background: transparent;
    border: none;
    color: #FFCC00;
}
.membershipBadge {
    background-image: url('/src/Images/EditBadge.png');
    width: 44px;
    height: 18px;
    padding-top: 4px;
    border-radius: 5px;
    color: grey;
    cursor: pointer;
    text-align: center;
}
.wallet-copy{
    border: none;
    margin-right: 5px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/src/Images/Copy.png');
    height: 12px;
    width: 12px;
}
.number-item {
    display: inline-block;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    text-align: center;
    color: grey;
    user-select: none;
    font-size: 18px;
}
.number-item.selected {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    transform: scale(1.2);
}
.number-item:hover {
}