.matchSlot {
    margin-bottom: 8px;
    margin-left: 8px;
    color: dimgrey;
    flex: 0 0 80vw;
    display: grid;
    width: 95vw;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3,113px);
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
    border-radius: 8px;
    background-color: #1F2F79;
}
.matchSlot.mostPopularMatch{
    margin-bottom: 30px;
    margin-left: 0;

}
.liveMatchSlot{
    width: 200px;
    height: 60px;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 8px;
    border: 1px;
    background: transparent;
}
.liveMatchSlot.live{
    border: 1px solid #FFCC00;
}
.leaderboardSlot{
    width: 100vw;
    height: 40px;
    border-top: 1px dashed #FFFFFF33;
    display: flex;
    align-items: center;
    justify-content: center;
}
.membershipSlotItem{
    background: #FFFFFF33;
    display:grid;
    align-items: center;
    justify-content: left;
    margin-top: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    grid-template-columns: repeat(2,47vw);
    grid-template-rows: 1fr;
    grid-template-areas:"first-column" "second-column";
    border-radius: 4px;
}
.membershipSlotItem.vip{
    background: #FFFFFF33;
    border: 1px solid white;
    border-radius: 4px;
}

.badgeSlots{
    width:33vw;
    height:17px;
    background: #FFFFFF1A;
    margin-top:4px;
    padding-top:4px;
    border-radius:4px;
    font-size:12px;
    font-weight:normal;
}