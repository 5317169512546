
* {
  margin: 0;
  padding: 0;
  color: white;
}
button:focus, input:focus, select:focus, textarea:focus {
  outline: none;
}

.mainPageItemContainer{
  margin-top: 16px;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "first-row" "second-row" "third-row";
  gap: 8px;
}
.mostPopularMatchContainer{
  display: grid;
  width: 95vw;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  grid-template-areas: "first-column" "second-column";
  border-radius: 8px;
  border: 3.5px solid #FFCC00;
  background-color: #1F2F79;
}
.navbar {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}
.navbar a {
  text-decoration: none;
  padding-top: 30px;
}
.buttonContainer{
  display: flex;
  justify-content: center;
  margin-top: 8px;
  gap: 10px;
}
.scroll-container-horizontal {
  scrollbar-width: none;
  width: 95vw;
  height: 60px;
  gap: 8px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: left;
  align-items: center;
  display: flex;
}
.match-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Makes sure the overlay is above the content */
  border-radius: 8px;
}
.match-container{
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5,40px);
  align-items: center;
  justify-content: center;
  text-overflow: clip;
}
.vertical-scroll-container{
  margin-top: 6px;
  scrollbar-width: none;
  width:100vw;
  justify-content: center;
  align-items: center;
}
.vertical-scroll-leaderboard{
  margin-top: 6px;
  scrollbar-width: none;
  width:100vw;
  justify-content: center;
  align-items: center;
}
.horizontal-number-slider {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #121C4A;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
}
.horizontal-number-slider::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.inviteUserContainer{
  margin-top:8px;
  display: grid;
  grid-template-columns: repeat(5, 68px);
  grid-gap: 8px;
  justify-content: left;
  align-content: center;
}
.podiumContainer{
  display: grid;
  justify-content: center;
  align-items: center;
  height: 108px;
  grid-template-columns: repeat(3,106px);
  margin-top: 10px;
}
.profileDetails{
  display: grid;
  place-items: center;
  grid-template-rows: auto auto auto;
  grid-template-areas: "first-row-left first-row-right"
    "second-row second-row"
    "third-row third-row"
    "forth-row forth-row";
  margin-top: 20px;
}
.profile-detail-style{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  text-align: left;
  font-size: 12px;
}
.profile-pic-big{
  width: 26vw;
  height: 26vw;
  grid-area: first-row-left;
  background-size: 26vw;
  background-color: #2F2F2F;
  background-image: url('/src/Images/ProfilePic.png');
  background-repeat: no-repeat;
  border-radius: 10px;
}
.wallet-address{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 67vw;
  height: 24px;
  background-color: #FFFFFF33;
  border-radius: 5px;
}
.rows-container {
  height: 98px;
  grid-area: first-row-right;
}
.profile-edit-verified{
  display: flex;
  justify-items: end;
  font-size: 12px;
  gap: 5px;
}
.rewardText{
  padding-left: 8px;
  padding-top: 8px;
  width: 338px;
  height: 28px;
  border-radius: 4px;
  border: none;
  background-color: #FFFFFF0D;
  text-align: left;
}
.headerText{
  font-size: 15px;
  font-weight: bold;
}
.headerText2{
  text-align: left;
  font-size: 12px;
  margin-left: 12px;
}
.bigHeader{
  font-size: 20px;
  height: 24px;
  font-weight: 600;
  padding-top: 16px;
}
.slotTextLeft{
  text-align: left;
  font-size: 12px;
  font-weight: normal;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  width: 358px;
  height: 122px;
  border: 1px solid grey;
  background: #1F2F79;
  padding-top: 15px;
  border-radius: 5px;
  top: 20%;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #2B43B0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
