.App {
  width: 100vw;
  height:100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #2B43B0;
}
button:focus, input:focus, select:focus, textarea:focus {
  outline: none;
}

